.main {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 6rem;
  min-height: 100vh;
  background: linear-gradient(180deg, rgb(20, 20, 72) 0%, rgba(2,0,36,1) 36%);
}

.description {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-family: var(--font-mono);
}

.description h1 {
  font-size: 20vh;
}

/* mobile */
@media (max-width: 750px) {
  .description h1 {
    font-size: 5vh;
  }

}